import React, {Fragment, useState, useEffect} from 'react'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import styled, {ThemeProvider} from 'styled-components'
import {themes} from '../styles/themes'
import {Homepage} from './Homepage'
import {Environments} from './Environments'
import {NavBar} from './NavBar'

const THEME_KEY = '@homepage/theme'

const Container = styled.div`
  background-color: ${(props) => props.theme.background};
  min-height: 100vh;
`

export const Routes = () => {
  const [theme, switchTheme] = useState(
    localStorage.getItem(THEME_KEY) || 'light'
  )

  useEffect(() => {
    localStorage.setItem(THEME_KEY, theme)
  }, [theme])

  return (
    <ThemeProvider theme={themes[theme]}>
      <Router>
        <Fragment>
          <NavBar
            theme={theme}
            switchTheme={() =>
              switchTheme((theme) => (theme === 'light' ? 'dark' : 'light'))
            }
          />

          <Container className="section">
            <div className="container">
              <Route path="/" exact component={Homepage} />
              <Route path="/envs" exact component={Environments} />
            </div>
          </Container>
        </Fragment>
      </Router>
    </ThemeProvider>
  )
}
