import React from 'react'
import firebase from 'firebase/app'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import PropTypes from 'prop-types'
import {UI_CONFIG} from '../constants/firebaseConfig'
import {FullPageHero} from '../components/FullPageHero'

export class LoginScreen extends React.Component {
  static propTypes = {
    isWrongMail: PropTypes.bool,
  }

  render() {
    const {isWrongMail} = this.props

    return (
      <FullPageHero>
        <h1 className="title is-1">
          {isWrongMail
            ? 'Please login with a Shippr account 🤔'
            : 'Welcome @ Shippr'}
        </h1>
        <StyledFirebaseAuth
          uiConfig={UI_CONFIG}
          firebaseAuth={firebase.auth()}
        />
      </FullPageHero>
    )
  }
}
