import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Link} from 'react-router-dom'

const ImageContainer = styled.div`
  height: 6.5rem;
  margin-bottom: 0.25rem;
  img {
    width: 100%;
    padding: 0.25rem;
  }
`

const Logo = styled.div`
  width: 6.5rem;
  text-align: center;
  padding-top: 0.5rem;
  user-select: none;

  a {
    display: block;
    text-decoration: none;
    color: ${(props) => props.theme.textColor};

    &:hover {
      font-weight: bold;
    }
  }
`

export const ApplicationLogo = ({name, img, link, internal}) => (
  <Logo>
    {internal ? (
      <Link to={link}>
        <ImageContainer>
          <img src={img} alt={name} />
        </ImageContainer>
        <div>{name}</div>
      </Link>
    ) : (
      <a href={link} target="_blank" rel="noopener noreferrer">
        <ImageContainer>
          <img src={img} alt={name} />
        </ImageContainer>
        <div>{name}</div>
      </a>
    )}
  </Logo>
)

ApplicationLogo.propTypes = {
  name: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  internal: PropTypes.bool,
}
