import React from 'react'
import {Link} from 'react-router-dom'
import firebase from 'firebase/app'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import shipprLogo from '../assets/images/shipprLogo.png'

const Nav = styled.nav.attrs((props) => ({
  className: `navbar ${props.theme.navbar}`,
}))``

export const NavBar = ({switchTheme}) => {
  const userName = firebase.auth().currentUser.displayName.split(' ')[0]

  return (
    <Nav aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img src={shipprLogo} alt="shippr logo" />
          </Link>

          <button
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </button>
        </div>

        <div className="navbar-menu">
          <div className="navbar-start">
            <div className="navbar-item">{`Welcome home ${userName}`}</div>
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <button className="button" onClick={() => switchTheme()}>
                  Switch Theme
                </button>
              </div>
            </div>
            <div className="navbar-item">
              <div className="buttons">
                <button
                  className="button"
                  onClick={() => firebase.auth().signOut()}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Nav>
  )
}

NavBar.propTypes = {
  switchTheme: PropTypes.func.isRequired,
}
