// This needs to be kept in sync with the database rules. See database.rules.bolt
export const SUPER_ADMINS = [
  "romain.syed@shippr.io",
  "harold.waterkeyn@shippr.io",
  "quentin.goossens@shippr.io",
  "kevin.delval@shippr.io",
  "alexina.smet@shippr.io",
  "florian.knop@shippr.io",
  "bjorn.krols@shippr.io",
  "jeremy.page@shippr.io",
];
