import React from 'react'
import PropTypes from 'prop-types'

export const FullPageHero = ({children}) => (
  <section className="hero is-fullheight is-warning is-bold">
    <div className="hero-body">
      <div className="container has-text-centered">{children}</div>
    </div>
  </section>
)

FullPageHero.propTypes = {
  children: PropTypes.node.isRequired,
}
