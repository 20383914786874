import React, {useEffect, useState} from 'react'
import firebase from 'firebase/app'
import {LoginScreen} from './screens/LoginScreen'
import {FullPageHero} from './components/FullPageHero'
import {Spinner} from './components/Spinner'
import {Routes} from './screens/Routes'

export const App = () => {
  const [loading, setLoading] = useState(true)
  const [access, setAccess] = useState(false)
  const [isWrongMail, setWrongMail] = useState(false)

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          if (user.email.endsWith('@shippr.io') && user.emailVerified) {
            setAccess(true)
          } else {
            setWrongMail(true)
          }
        } else {
          setAccess(false)
        }
        setLoading(false)
      })
    return () => {
      unregisterAuthObserver()
    }
  }, [])

  if (loading) {
    return (
      <FullPageHero>
        <Spinner />
      </FullPageHero>
    )
  }

  if (access) {
    return <Routes />
  }

  return <LoginScreen isWrongMail={isWrongMail} />
}
