export const themes = {
  dark: {
    navbar: 'is-dark',
    background: 'rgb(38, 40, 43)',
    tilesHover: 'rgb(45, 48, 51)',
    textColor: 'rgb(180, 180, 180)',
    linksColor: '#8d2663',
  },
  light: {
    navbar: 'is-white',
    background: 'white',
    tilesHover: 'white',
    textColor: 'black',
    linksColor: 'blue',
  },
}
