import React, {Component} from 'react'
import axios from 'axios'
import styled from 'styled-components'
import firebase from 'firebase/app'
import {assocPath, path} from 'ramda'
import {FaExclamationTriangle} from 'react-icons/fa'
import {SUPER_ADMINS} from '../constants/superAdmins'
import {ENVS_LIST} from '../constants/environments'

const Light = styled.span`
  display: inline-block;
  margin: 0 1rem;
  font-size: 0.9rem;
`

const Mono = styled.span`
  font-family: monospace;
  font-weight: bold;
`

const colorFunc = (props) => props.theme.textColor

const ColorContainer = styled.div`
  color: ${colorFunc};

  h2 {
    color: ${colorFunc};
  }

  h4 {
    color: ${colorFunc};
  }
`

const Links = styled.a`
  color: ${(props) => props.theme.linksColor};

  :hover {
    color: ${(props) => props.theme.textColor};
  }
`

const Box = styled.div`
  width: 30%;
  border-radius: 5px;
  padding: 2rem;
  margin-bottom: 2rem;
`

const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Button = styled.div`
  margin-right: 1rem;
`

const getMaintenanceRef = () => firebase.database().ref(`/maintenance`)

export class Environments extends Component {
  state = {envs: {}, maintenance: null}

  isSuperAdmin = () => SUPER_ADMINS.includes(firebase.auth().currentUser.email)

  getBackendShipprEnv = (envName, service) => {
    if (envName === 'Production') {
      return 'production'
    }

    return path(['envs', envName, service, 'shippr_env'], this.state)
  }

  isBackendUp = (envName, service) =>
    !!this.getBackendShipprEnv(envName, service)

  isUnderMaintenance = (envName, service) =>
    path(
      ['maintenance', this.getBackendShipprEnv(envName, service)],
      this.state
    )

  componentDidMount() {
    getMaintenanceRef().on('value', (data) => {
      this.setState({maintenance: data.val()})
    })

    ENVS_LIST.forEach((env) => {
      if (env.services) {
        env.services.forEach(({name, url}) => {
          axios
            .get(`${url}/health/version`)
            .then(({data}) => {
              if (data.version) {
                this.setState((state) =>
                  assocPath(['envs', env.name, name], data, state)
                )
              } else {
                this.setState((state) =>
                  assocPath(['envs', env.name, name], false, state)
                )
              }
            })
            .catch(() => {
              this.setState((state) =>
                assocPath(['envs', env.name, name], false, state)
              )
            })
        })
      }
    })
  }

  setMaintenance = (envName, service, value) => {
    return getMaintenanceRef()
      .child(this.getBackendShipprEnv(envName, service))
      .set(value ? true : null)
  }

  render() {
    const {envs} = this.state

    return (
      <div className="content">
        <BoxContainer>
          {ENVS_LIST.map((e) => (
            <Box
              className={`${
                e.services
                  ? this.isBackendUp(e.name, 'backend') &&
                    'has-background-warning'
                    ? 'has-background-white-ter'
                    : 'has-background-warning'
                  : 'has-background-white-ter'
              }`}
              key={e.name}
            >
              <h4 className="title is-4">
                <Title>
                  {e.name}
                  {e.icon && <Icon src={e.icon} alt="icon" />}
                </Title>
              </h4>
              <p className="subtitle">
                <Links href={e.link} target="_blank" rel="noopener noreferrer">
                  {e.link}
                </Links>
              </p>

              {e.netlifyBadge && e.netlifyLink && (
                <div>
                  <a href={e.netlifyLink}>
                    <img alt="Netlify status" src={e.netlifyBadge} />
                  </a>
                </div>
              )}

              {e.services &&
                e.services.map(({name}) => (
                  <div key={`${e.name}${name}`}>
                    <span
                      className={`tag ${
                        this.isBackendUp(e.name, name)
                          ? 'is-success'
                          : 'is-danger'
                      }`}
                    >
                      {name}
                    </span>
                    <Light>
                      {path([e.name, name, 'version_date'], envs) || 'Down'}
                    </Light>
                  </div>
                ))}

              <Button>
                {(e.name !== 'Production' || this.isSuperAdmin()) && (
                  <button
                    className="button is-small"
                    onClick={() =>
                      this.setMaintenance(
                        e.name,
                        'backend',
                        !this.isUnderMaintenance(e.name, 'backend')
                      )
                    }
                  >
                    <FaExclamationTriangle
                      color={
                        this.isUnderMaintenance(e.name, 'backend')
                          ? '#ffc400'
                          : '#ccc'
                      }
                    />
                    Maintenance
                  </button>
                )}
              </Button>
            </Box>
          ))}
        </BoxContainer>

        <ColorContainer>
          <h2>How to connect?</h2>

          <h4>Admin</h4>
          <p>
            To connect as an admin use the following email address:{' '}
            <Mono>&lt;your-first-name&gt;+admin@shippr.io</Mono> e.g.{' '}
            <Mono>romain+admin@shippr.io</Mono>
            <br />
            The password can be found in <b>1Password</b> under the name{' '}
            <Mono>Shippr Admin</Mono>
          </p>

          <h4>Customer</h4>
          <p>
            To connect as a customer, use{' '}
            <Mono>&lt;your-first-name&gt;+customer@shippr.io</Mono> e.g.{' '}
            <Mono>romain+customer@shippr.io</Mono>
            <br />
            The password is the same as the one for the admin account
          </p>

          <h4>Carrier</h4>
          <p>
            To connect on the carrier app, use{' '}
            <Mono>&lt;your-first-name&gt;+carrier@shippr.io</Mono> e.g.{' '}
            <Mono>romain+carrier@shippr.io</Mono>
            <br />
            The password is the same as the one for the admin account
          </p>
        </ColorContainer>
      </div>
    )
  }
}
