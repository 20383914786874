export const LINK_LIST = [
  {
    img: require('../assets/images/favicon.png'),
    name: 'Dashboard',
    link: 'https://dashboard.shippr.io',
  },
  {
    img: require('../assets/images/qa-logo.png'),
    name: 'Environments',
    link: '/envs',
    internal: true,
  },
  {
    img: require('../assets/images/how-to.png'),
    name: 'How to',
    link: 'https://drive.google.com/drive/u/2/folders/0AIfySWdZHX_nUk9PVA?ogsrc=32',
  },
  {
    img: require('../assets/images/onepassword.png'),
    name: '1Password',
    link: 'https://shippr.1password.com/signin',
  },
  {
    img: require('../assets/images/slack.png'),
    name: 'Slack',
    link: 'https://shippr-office.slack.com',
  },
  {
    img: require('../assets/images/drive.png'),
    name: 'Drive Team',
    link: 'https://drive.google.com/drive/u/2/team-drives',
  },
  {
    img: require('../assets/images/roadmap.svg'),
    name: 'Roadmap V2',
    link: 'https://docs.google.com/spreadsheets/d/1fDuqRnjf6fodo3KCU_CQliVI7Mf5dRU7hdR0CfAyy4w/edit#gid=0',
  },
  {
    img: require('../assets/images/out-of-office.png'),
    name: 'Out of office',
    link: 'https://shippr-holidays.web.app',
  },
  {
    img: require('../assets/images/marketing-hub.svg'),
    name: 'Marketing',
    link: 'https://docs.google.com/spreadsheets/d/1rnRWgRyMoEcKM3uMOC8LJghmxCfbIAHSj7N5nTLgH9Y',
  },
  {
    img: require('../assets/images/clubhouse.svg'),
    name: 'Clubhouse',
    link: 'https://app.clubhouse.io/shippr',
  },
  {
    img: require('../assets/images/redash.png'),
    name: 'Redash',
    link: 'https://app.redash.io/shippr/',
  },
  {
    img: require('../assets/images/github.svg'),
    name: 'Github',
    link: 'https://github.com/shippr-team',
  },
  {
    img: require('../assets/images/netlify.png'),
    name: 'Netlify',
    link: 'https://app.netlify.com',
  },
  {
    img: require('../assets/images/datadog.svg'),
    name: 'Datadog',
    link: 'https://app.datadoghq.eu/apm/home',
  },
  {
    img: require('../assets/images/api-documentation.png'),
    name: 'API documentation',
    link: 'https://api-doc.shippr.io/',
  },
  {
    img: require('../assets/images/brand-center.svg'),
    name: 'Branding',
    link: 'https://drive.google.com/drive/folders/1R_dGmLyQ8_Xnbqel1tZaswBW1OVjmr-Q?usp=sharing',
  },
  {
    img: require('../assets/images/storybook.png'),
    name: 'Storybook',
    link: 'https://storybook.shippr.io',
  },
  {
    img: require('../assets/images/zapier.png'),
    name: 'Zapier',
    link: 'https://zapier.com/app/dashboard',
  },
  {
    img: require('../assets/images/operational-briefing.svg'),
    name: 'Operational briefing',
    link: 'https://shipprteam.typeform.com/to/JWj4H5',
  },
  {
    img: require('../assets/images/feedback_founders.jpg'),
    name: 'Founders Feedback',
    link: 'http://freesuggestionbox.com/pub/lubifny',
  },
  {
    img: require('../assets/images/signature.svg'),
    name: 'Email signatures',
    link: 'https://signatures.shippr.io/',
  },
  {
    img: require('../assets/images/deepl.jpg'),
    name: 'Deepl',
    link: 'https://www.deepl.com/translator',
  },
  {
    img: require('../assets/images/gdpr.png'),
    name: 'GDPR',
    link: 'https://www.notion.so/GDPR-Shippr-7499da9209024ef9a6b4373420dc74b1',
  },
  {
    img: require('../assets/images/academy.png'),
    name: 'Shippr Academy',
    link: 'https://academy.shippr.io/',
  },
]
