import prodIcon from '../assets/images/favicon.png'
import devIcon from '../assets/images/favicon_blue.png'
import demoIcon from '../assets/images/favicon_black.png'

const DEMO_CLUSTER_DOMAIN = 'demo.shippr.dev'
const DEV_CLUSTER_DOMAIN = 'dev.shippr.dev'

export const ENVS_LIST = [
  {
    name: 'Production',
    link: 'https://dashboard.shippr.io',
    services: [
      {name: 'backend', url: 'https://backend.shippr.io'},
      {name: 'api', url: 'https://api.shippr.io/v1'},
    ],
    icon: prodIcon,
  },
  {
    name: 'Dev',
    link: 'https://dashboard.shippr.dev',
    services: [
      {name: 'backend', url: `https://backend.${DEV_CLUSTER_DOMAIN}`},
      {name: 'api', url: `https://api.${DEV_CLUSTER_DOMAIN}/v1`},
    ],
    icon: devIcon,
  },
  {
    name: 'Demo',
    link: 'https://demo.shippr.dev',
    services: [
      {name: 'backend', url: `https://backend.${DEMO_CLUSTER_DOMAIN}`},
      {name: 'api', url: `https://api.${DEMO_CLUSTER_DOMAIN}/v1`},
    ],
    icon: demoIcon,
  },
  {
    name: 'Signatures',
    link: 'https://signatures.shippr.io',
    netlifyBadge:
      'https://api.netlify.com/api/v1/badges/08e4b544-8d8a-45ef-8489-acec5b27e141/deploy-status',
    netlifyLink:
      'https://app.netlify.com/sites/signature-generator-shippr/deploys',
  },
  {
    name: 'API Doc',
    link: 'https://api-doc.shippr.io',
    netlifyBadge:
      'https://api.netlify.com/api/v1/badges/597c7b1a-a039-4082-b6e3-e407ae8670b7/deploy-status',
    netlifyLink: 'https://app.netlify.com/sites/objective-booth-1f17b5/deploys',
  },
]
