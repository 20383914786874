import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyCKx_9jGyP3B2S5ZIGhKKyzLC90G4tOnoE',
  authDomain: 'shippr-homepage.firebaseapp.com',
  databaseURL: 'https://shippr-homepage.firebaseio.com',
  projectId: 'shippr-homepage',
  storageBucket: 'shippr-homepage.appspot.com',
  messagingSenderId: '1017598395528',
}

export const UI_CONFIG = {
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  signInFlow: 'popup',
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  },
}
