import React from 'react'
import styled from 'styled-components'
import {splitEvery} from 'ramda'
import {LINK_LIST} from '../constants/linkList'
import {ApplicationLogo} from '../components/ApplicationLogo'

const Content = styled.div`
  display: flex;
  margin: 1rem auto 0;
  justify-content: space-between;
`

const BodyWrapper = styled.div`
  margin: 0 auto;
  font-family: 'Open Sans';
  font-size: 0.9rem;
`

const getSplittedList = () => splitEvery(4, LINK_LIST)

export const Homepage = () => (
  <BodyWrapper>
    <div>
      {getSplittedList().map((subArray, index) => (
        <Content key={index}>
          {subArray.map(({img, name, link, internal}, index) => (
            <ApplicationLogo
              key={index}
              img={img}
              name={name}
              link={link}
              internal={internal}
            />
          ))}
        </Content>
      ))}
    </div>
  </BodyWrapper>
)
